/*Data.js */
export const tours = [
  {
    id: 1,
    type: "cultural",
    name: "Azerbaijan ",
    desc: "With all its colours",
    days: 8,
    nights: 7,
    imagelink: "tour1.svg",
    tourProgram: {
      headers: [
        "Day 1:  Arrival in Baku",
        "Day 2:  Baku – Gobustan (60 km)",
        "Day 3:  Baku – Shamakhi – Sheki (300 km)",
        "Day 4: Sheki",
        "Day 5: Sheki – Goygol – Ganja (232 km)",
        "Day 6: Ganja – Baku (365 km)",
        "Day 7: Baku – Absheron – Baku (60 km)",
        "Day 8: Transfer to the airport (25 km)",
      ],
      bodies: [
        "Pick up at the airport by the local guide; Transfer to the hotel (25 km); Overnight stay at the hotel.",
        `Breakfast at the hotel
    Visit to the panoramic site, the highest point of Baku called the Alley of Martyrs (Upland Park during Soviet times). Here you can admire the beautiful bay of Baku which resembles that of Naples, the city with which it is twinned;
    Visit to the famous old city, passing in front of the elegant buildings of early 20th century built by wealthy Azerbaijani oil magnates. In the old city, visit to the several historical monuments, Shirvanshahs Palace, caravanserais, the remains of St. Bartholomew church, the old Hammam, the famous Maiden Tower and ancient mosques;
    Second breakfast inside the walls of the old city;
    Departure to Gobustan, protected area 60 km from Baku. It is the UNESCO heritage site, where there are more than 7000 rock paintings. Visit to the contemporary museum where there are explanations with the help of modern technology, as well as photos, videos, samples of the engraved stones. Acquaintance with the daily life of primitives, who lived thousand years ago, such as hunting, feeding and burial scenes. Visit to the Roman Stone engraved by the Roman soldiers BC. Continue to the rocks where there are several samples of rock art;
    Visit to the mud volcanoes, one of the natural beauties of Azerbaijan. There are more than a hundred volcanoes with different heights, which make a lunar effect and continuously erupt liquid and mud;
    Visit to Bibi-Heybat Mosque;
    Dinner at the restaurant;
    Overnight stay at the hotel.`,
        `Breakfast at the hotel;
Departure to Sheki;
Stop at the Diri Baba Mausoleum built between the rocks in 1402;
Stop in Shamakhi, once the capital of the Shirvanshahs State. Visit to the oldest mosque in Azerbaijan. Visit to the impressive Yeddi Gumbaz mausoleum;
Lunch;
Visit to the Orthodox Church in Nij village;
Arrival in Sheki;
Dinner;
Overnight stay.`,
        `Breakfast at the hotel;
Departure to Kish village, where you will visit the Paleo-Christian church built between 1st and 5th centuries during the era of the ancient kingdom of the Caucasian Albania;
Visit to local bazaar;
Lunch at the restaurant;
Visit to the beautiful Khan Palace, which is famous for its rich decorations, the Sheki House of Artisans, the magnificent Caravanserai;
After the break, visit to Sheki Juma Mosque.
Dinner;
Overnight stay.`,
        `Breakfast;
Departure to Goygol (190 km). Visit to the German quarter where it is located original German houses of 19th century and the Lutheran Church;
Visit to magnificent Goygol National Park. Here you can admire beautiful natural Goygol Lake, which was created as a result of earthquake in 1139;
Lunch;
Departure to Ganja, the second largest city of Azerbaijan;
Visit to Shah Abbas Mosque (Juma Mosque), Javad Khan Mausoleum, the Alexander Nevski Church, the Chokek Hammam, the Bottle House, the Khan Garden Park and the Government Square;
Dinner at the restaurant;
Overnight stay at the hotel.`,
        `Departure to Baku (365 km);
On the way, stop at the mausoleum of Nizami (famous poet of the East) and at the mausoleum of Imamzadeh (son of an apostle of Muhammad);
Lunch on the way;
Arrival in Baku;
Free time. Walking at the seaside boulevard;
Dinner;
Overnight stay.`,
        `Visit to Absheron peninsula. Arrival at Atashgah – fire temple in Surakhani village, religious worship place of Zoroastrians;
Visit to Mardakan Fortress;
Visit to Mirmovsum Aga Mausoleum in Shuvelan village, famous for its gold and silver mirror decoration;
Lunch;
Visit to Yanardag (burning mountain), one of the wonderful natural beauties of Azerbaijan;
Return to Baku. Visit to beautiful Cultural Center designed by Zaha Hadid (*entrance fee is not included). The architecture of the building is impressive and futuristic;
Visit to magnificent Azerbaijan National Carpet Museum. The building reminds a rolled carpet;
Dinner at the hotel;
Baku night excursion to the beautiful viewpoints;
Overnight stay at the hotel.`,
        `Transfer to the airport  from hotel.`,
      ],
    },
  },
  {
    id: 2,
    type: ["cultural", "eco"],
    name: "Azerbaijan",
    desc: "From Culture to Nature",
    days: 8,
    nights: 7,
    imagelink: "tour3.svg",
  },
  {
    id: 3,
    type: "cultural",
    name: "Azerbaijan- Georgia",
    desc: "The important cities of the Caucasus",
    days: 10,
    nights: 9,
    imagelink: "tour2.svg",
  },

  {
    id: 4,
    type: "cultural",
    name: "Azerbaijan - Georgia",
    desc: "The Pearls of the Caucasus",
    days: 8,
    nights: 7,
    imagelink: "tour4.svg",
  },
  {
    id: 5,
    name: "Azerbaijan",
    type: "eco",
    desc: "The Mountain Villages",
    days: 8,
    nights: 7,
    imagelink: "tour5.svg",
  },
  {
    id: 6,
    type: "cultural",
    name: "Azerbaijan - Baku",
    desc: "The flames swaying in the wind",
    days: 8,
    nights: 7,
    imagelink: "tour1.svg",
  },
  {
    id: 7,
    type: "health",
    name: "Azerbaijan",
    desc: "Health & Wellness",
    days: "X",
    nights: "X",
    imagelink: "tour3.svg",
  },
  {
    id: 8,
    type: "sport",
    name: "Formula 1 Baku Circuit",
    desc: "Sport & Event",
    days: "7",
    nights: "6",
    imagelink: "tour7.svg",
    tourProgram: {
      headers: [],
      bodies: [],
    },
  },
];

export const aboutAze = [
  {
    key: "General Information",
    value: `Azerbaijan, officially the Republic of Azerbaijan, is the state located in the Caucasus region. Rich in oil, the country is located in Asia Transcaucasia, south of the mountainous watershed that separates it conventionally from Europe. Azerbaijan is the largest country in the Caucasus both in terms of area and population, bordering the Caspian Sea to the east, Russia to the north, Georgia and Armenia to the west, and Iran to the south.
        From geographic point of view, the State of Azerbaijan is not the European one, however, it is often considered as such thanks to historical and cultural considerations. In fact, Azerbaijan is a member of the Council of Europe. The country is also a member of the European Broadcasting Union (EBU) and therefore, it participates in the Eurovision Song Contest, won in 2011 and hosted the following year.`,
  },
  {
    key: "Hospitality",
    value: `Guests are given the places of honor in the Azerbaijani traditions and treated with the utmost respect. Those who will have the opportunity to meet or stay with a family in Azerbaijan, definitely, they will see well-known Azerbaijani hospitality. The traditions of hospitality in Azerbaijan have come a long way; however, there are also modern manners that the families welcome the guests today.
    
        In a classical piece of Azerbaijani literature "Dede-Gorgud Dastani", it is said that "let the houses that no longer welcome guests collapse".
        
        There are considerable number of hotels of different types in Azerbaijan that can satisfy any visitor. Great number of hotels are situated in Baku, including budget and luxury hotels.`,
  },
  {
    key: "Cuisine",
    value: `As the main dishes, Azerbaijani cuisine has Plov with saffron aroma, coal-roasted kebabs, freshly-caught fish, sweet fruit and honey for dessert. The cuisine of Azerbaijan is full of flavours and surprises and is a vital part of the country and its culture. The flavours and ingredients used reflect the ingredients grown or available in Azerbaijan, although the commercial exchanges have brought new flavours from the entire world. Even the cooking techniques reflect the lifestyle and local traditions, and the names of the Azerbaijani dishes derive from the terms used in Azerbaijan.
    
        Hospitality is an important part of the Azerbaijani culture, and the cuisine is not an exception. Guests are treated to an abundance of dishes, and leaving food on the plate can be considered rude, even if you are already full. Food holds sacred place and sharing your food, even bread with another person creates a strong bond. Food culture is very rich. For example, the Dolma (stuffed grape leaves) and its central place in the Azerbaijani culture and among families have been recognized by the UNESCO Intangible Cultural Heritage.
        
        Many dishes are delicious and healthy, which is why Azerbaijan is a country of centenarians. Beef and mutton are common, the same as poultry, fish, fruit and vegetables. The spices include dill, coriander, mint, chive and basil. They can be found in many of dishes, giving them rich and full flavours. The result is a wide variety of dishes that are hearty and nutritious, full of familiar and unusual flavours.
        
        But wait, that’s not all - there are endless foods to try in Azerbaijan. If you would like a hearty soup (like Piti) or a quick snack (like Kutab) or something to satisfy a sweet tooth (Pakhlava), you will find more to explore. The more you travel to Azerbaijan, the more you will discover, as every region (and every cook) has their own recipes that bring new flavours and delights`,
  },
  {
    key: "Geography",
    value: `Territory of the country covers the area of 86 600 km². It is located on the southern slopes of the Caucasus. Densely inhabited part of the country extends around the downstream of the Kura River that drains into the Caspian Sea, which washes the entire eastern part of Azerbaijan. The country borders Russia to the north, Armenia and Georgia to the west, and Iran to the south. Nakhchivan Autonomous Republic is enclosed between Armenia, Iran and Turkey. The capital is Baku city, and another important city is Ganja.
    
        The question whether Azerbaijan can be considered a transcontinental country lying on both sides of Asia and Europe is controversial. Having regarded the Kuma-Manych Depression as the natural boundary between Europe and Asia, the whole territory of Azerbaijan, in fact, would be on the Asian continent. If this border is along the Caucasian watershed or the Kura River and Rioni River, then a part of the Azerbaijani territory would be in Europe.
        
        Azerbaijan is mostly mountainous.`,
  },
  {
    key: "Climate",
    value: `In winter, the Greater Caucasus protects the country from polar influences, while summers are very hot. Towards the south from the capital instead, autumn is very rainy, while the rest of the country has a pleasant climate. 9 out of 11 climate zones are present in Azerbaijan.`,
  },
  {
    key: "Population and Ethnicity",
    value: `Azerbaijan is a multi-ethnic country. The population is mainly composed of Azerbaijanis (approximately 90%).
        The current population of Azerbaijan is about 10 million.`,
  },

  {
    key: "Language",
    value: `The official language is Azerbaijani, a Turkic language, written in Azerbaijani with the Latin alphabet, which replaced the Cyrillic alphabet. There is a small Russian-speaking minority (3.5%), Lezgi (1.9%) in the north and Talish (1.8%) in the south of the country.
    
        Obviously, as a legacy of the Soviet period, most of the Azerbaijani population speaks Russian fluently. Currently, majority of young people speak English well enough.`,
  },
  {
    key: "Political structure",
    value: `The structure of the political system in Azerbaijan was redefined by way of adoption of new Constitution on 12 November 1995. According to Article 23 of the Constitution, the state symbols of the Republic of Azerbaijan are the State Flag, the State Emblem and the State Anthem.
    
        The Government of Azerbaijan is based on the principle of separation of powers: legislative power, executive power and judicial power. Legislative power is exercised by the unicameral National Assembly (Milli Mejlis). Given the particular status of autonomy of Nakhchivan, powers, similar to those of the Milli Mejlis, are attributed to the Ali Mejlis of Nakhchivan Autonomous Republic. The executive power is vested in the President, who is elected for a seven-year term by way of direct election. The judicial power is exercised by the Constitutional Court, the Supreme Court and the courts of appeal, general courts and other specialised courts of the Republic of Azerbaijan.`,
  },
];
export const aboutAzePhotos = [
  { link: "aboutAze1.svg" },
  { link: "aboutAze2.svg" },
  { link: "aboutAze3.svg" },
  { link: "aboutAze1.svg" },
  { link: "aboutAze2.svg" },
  { link: "aboutAze3.svg" },
  { link: "aboutAze1.svg" },
  { link: "aboutAze2.svg" },
  { link: "aboutAze3.svg" },
];
